<template>
    <label>{{ label }}</label>
    <select
        :value="modelValue"
        :class="{'form-control': true, 'is-invalid': error, 'form-control-sm': isSmall}"
        @change="onSelectChange"
    >
        <option v-if="defaultOption" >{{ defaultOption }}</option>
        <option v-for="option in options" :key="option.id" :value="option.id">
            {{ option.name }}
        </option>
    </select>
    <span v-if="error" class="invalid-feedback">{{ error[0] }}</span>
</template>

<script>
export default {
    props: [
        'label',
        'options',
        'modelValue',
        'error',
        'defaultOption',
        'isSmall',
    ],
    emits: ['update:modelValue', 'content-changed'],
    methods: {
        onSelectChange(event) {
            this.$emit('update:modelValue', event.target.value);
            this.$emit('content-changed');
        },
    },
};
</script>
