import './bootstrap';
import './loader.js';
import './site.js';
import './Plugins/prism.js';

import.meta.glob([
	'../img/**',
]);

import Alpine from 'alpinejs';
import {createApp} from 'vue';

import {CkeditorPlugin} from '@ckeditor/ckeditor5-vue';  // Импорт плагина
import 'ckeditor5/ckeditor5.css';

import PostCreateEditComponent from "./Components/Admin/Post/PostCreateEditComponent.vue";
import PostContentEditComponent from "./Components/Admin/Post/PostContentEditComponent.vue";

// Инициализация AlpineJS
window.Alpine = Alpine;
Alpine.start();

// Создаем Vue приложение
createApp({
	components: {
		PostCreateEditComponent,
		PostContentEditComponent,
	},
})
	.use(CkeditorPlugin) // Регистрируем плагин CKEditor
	.mount('#app'); // Монтируем приложение на #app
