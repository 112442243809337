document.addEventListener("DOMContentLoaded", function () {
	const logoutLink = document.getElementById('logout-link');
	const logoutForm = document.getElementById('logout-form');

	if (logoutLink && logoutForm) {
		logoutLink.addEventListener('click', (e) => {
			e.preventDefault();
			logoutForm.submit();
		});
	}
});

document.addEventListener("DOMContentLoaded", function () {
	const languageSwitcher = document.querySelector('.language-switcher');
	const languageCurrent = document.querySelector('.language-current');
	const dropdown = document.querySelector('.language-switcher .dropdown');

	// Переключение отображения меню при клике на текущий язык
	languageCurrent.addEventListener('click', (e) => {
		e.stopPropagation();
		languageSwitcher.classList.toggle('open');
	});

	// Закрытие меню при клике вне его области
	document.addEventListener('click', (e) => {
		if (!languageSwitcher.contains(e.target)) {
			languageSwitcher.classList.remove('open');
		}
	});
});