<template>
    <div class="add-blocks">
        <button @click="$emit('add', { type: 'TEXT', index })" class="btn btn-info btn-icon"><i class="fa fa-font"></i></button>
        <button @click="$emit('add', { type: 'CODE', index })" class="btn btn-info btn-icon"><i class="fa fa-code"></i></button>
        <button @click="$emit('add', { type: 'IMAGE', index })" class="btn btn-info btn-icon"><i class="fa fa-image"></i></button>
        <button @click="$emit('add', { type: 'YOUTUBE', index })" class="btn btn-info btn-icon"><i class="fa-brands fa-youtube"></i></button>
    </div>
</template>

<script>
export default {
    props: {
        index: {
            type: Number,
        }
    }
};
</script>
