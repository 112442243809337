<template>
    <label>{{ label }}</label>
    <input
        type="text"
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
        :class="{'form-control': true, 'is-invalid': error}"
        :placeholder="placeholder"
    />
    <span v-if="error" class="invalid-feedback">{{ error[0] }}</span>
</template>

<script>
export default {
    props: [
        'label',
        'modelValue',
        'placeholder',
        'error',
    ],
};
</script>
