<template>
    <div class="container">
        <div
            v-for="(block, index) in form.blocks"
            :key="block.id"
            :class="['block', block.type.toLowerCase() + '-block']"
        >
            <div v-if="block.type === 'TEXT'">
                <text-block-editor
                    v-model="block.content"
                    @content-changed="saveChanges(block)"
                ></text-block-editor>
            </div>

            <div v-if="block.type === 'IMAGE'">
                <input
                    v-if="!block.content"
                    type="file"
                    class="form-control"
                    @change="uploadImage($event, index)"
                />

                <img
                    v-if="block.content"
                    :src="block.content"
                    class="w100"
                    alt="image">
            </div>

            <div v-if="block.type === 'CODE'">
                <code-block-editor
                    v-model="block.content"
                    @content-changed="saveChanges(block)"
                ></code-block-editor>
            </div>

            <div v-if="block.type === 'YOUTUBE'">
                <div
                    v-if="block.content"
                    v-html="block.content"
                    class="iframe100"
                ></div>
                <you-tube-block-editor
                    v-model="block.content"
                    @content-changed="saveChanges(block)"
                ></you-tube-block-editor>
            </div>

            <div class="toolbar">
                <AddBlockButtons @add="createBlock" :index="index" />

                <div class="actions">
                    <FormSelect
                        v-if="block.type === 'CODE'"
                        v-model="block.code_language"
                        :options="codeLanguage"
                        :error="errors.code_language"
                        :isSmall="true"
                        @content-changed="saveChanges(block)"
                    />

                    <!-- Показать кнопку "вверх", если это не первый элемент -->
                    <button
                        v-if="index > 0"
                        class="btn btn-primary btn-icon"
                        @click="moveBlockUp(index)"
                    >
                        <i class="fa-solid fa-arrow-up-wide-short"></i>
                    </button>

                    <!-- Показать кнопку "вниз", если это не последний элемент -->
                    <button
                        v-if="index < form.blocks.length - 1"
                        class="btn btn-primary btn-icon"
                        @click="moveBlockDown(index)"
                    >
                        <i class="fa-solid fa-arrow-down-short-wide"></i>
                    </button>

                    <!-- Кнопка для удаления блока -->
                    <button class="btn btn-danger btn-icon" @click="deleteBlock(index)">
                        <i class="fa fa-trash"></i>
                    </button>
                </div>

            </div>
        </div>

        <div class="toolbar" v-if="!this.form.blocks.length">
            <AddBlockButtons @add="createBlock" />

            <div class="actions"></div>
        </div>
    </div>
</template>

<script>
import TextBlockEditor from '../../Admin/Form/TextBlockEditor.vue';
import CodeBlockEditor from '../../Admin/Form/CodeBlockEditor.vue';
import AddBlockButtons from './AddBlockButtons.vue';
import FormSelect from "../../Admin/Form/FormSelect.vue";
import YouTubeBlockEditor from "../../Admin/Form/YouTubeBlockEditor.vue";

export default {
    props: {
        translation: Object,
        codeLanguage: Object,
    },
    components: {
        YouTubeBlockEditor,
        CodeBlockEditor,
        FormSelect,
        TextBlockEditor,
        AddBlockButtons,
    },
    data() {
        return {
            form: {
                blocks: []
            },
            errors: {},
        };
    },
    mounted() {
        this.form.blocks = this.translation.content_blocks;
    },
    methods: {
        async saveChanges(block) {
            try {
                const response = await axios.post(`/admin/posts/blocks/${block.id}/update`, {
                    content: block.content,
                    codeLanguage: block.code_language,
                });
                if (response.data.success) {
                    this.$set(this.form, 'blocks', response.data.blocks);
                }
            } catch (error) {
                this.errors = error.response?.data.errors || {};
            }
        },
        async uploadImage(event, index) {
            const file = event.target.files[0],
                blockId = this.form.blocks[index].id;

            if (!file) return;

            // Подготовка данных для отправки
            const formData = new FormData();
            formData.append('postId', this.translation.post.id);
            formData.append('image', file);

            try {
                const response = await axios.post(`/admin/posts/blocks/${blockId}/upload-image`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });

                if(response.data.success) {
                    this.form.blocks = response.data.blocks;
                }
            } catch (error) {
                console.error('Ошибка при загрузке изображения:', error);
            }
        },
        async moveBlockUp(index) {
            const blockId = this.form.blocks[index].id;

            const response = await axios.post(`/admin/posts/blocks/${blockId}/move-up`);
            if(response.data.success) {
                this.form.blocks = response.data.blocks;
            }
        },
        async moveBlockDown(index) {
            const blockId = this.form.blocks[index].id;

            const response = await axios.post(`/admin/posts/blocks/${blockId}/move-down`);
            if(response.data.success) {
                this.form.blocks = response.data.blocks;
            }
        },
        async createBlock({ type, index }) {
            const codeLanguage = type === 'CODE' ? 'php' : null;

            const nextOrder = index !== undefined
                ? this.form.blocks[index].order + 1
                : 1;

            const block = {
                translationId: this.translation.id,
                type: type,
                content: '',
                codeLanguage: codeLanguage,
                order: nextOrder,
            };

            try {
                const response = await axios.post(`/admin/posts/blocks/create`, block);
                if(response.data.success) {
                    this.form.blocks = response.data.blocks;
                }
            } catch (error) {
                this.errors = error.response?.data?.errors || {};
                console.error("Error creating block:", error);
            }
        },
        async deleteBlock(index) {
            let blockId = this.form.blocks[index].id;

            try {
                const response = await axios.post(`/admin/posts/blocks/${blockId}/delete`);
                if(response.data.success) {
                    this.form.blocks = response.data.blocks;
                }
            } catch (error) {
                this.errors = error.response?.data.errors || {};
            }
        },
    },
};
</script>
