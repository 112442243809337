export default {
	props: {
		modelValue: String,
	},
	data() {
		return {
			editorData: this.modelValue || '',
		};
	},
	emits: ['update:modelValue', 'content-changed'],
	watch: {
		modelValue(newValue) {
			if (newValue !== this.editorData) {
				this.editorData = newValue || '';
				this.adjustHeight();
			}
		},
		editorData(newValue) {
			this.$emit('update:modelValue', newValue);
			this.adjustHeight();
		}
	},
	mounted() {
		this.adjustHeight();
	},
	methods: {
		adjustHeight() {
			const textarea = this.$refs.textarea;
			if (textarea) {
				textarea.style.height = 'auto';
				textarea.style.height = `${textarea.scrollHeight}px`;
			}
		},
		emitContentChange() {
			this.$emit('content-changed');
		},
	},
};