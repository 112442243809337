<template>
    <textarea
        v-model="editorData"
        placeholder="YouTube код.."
        ref="textarea"
        @change="emitContentChange"
        @input="adjustHeight"
    ></textarea>
</template>

<script>
import autoResizeTextareaMixin from '../Mixin/autoResizeTextareaMixin';

export default {
    mixins: [autoResizeTextareaMixin],
};
</script>

