<template>
    <label>{{ label }}</label>
    <textarea
        :rows="rows"
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
        :class="{'form-control': true, 'is-invalid': error}"
        :placeholder="placeholder"
    ></textarea>
    <span v-if="error" class="invalid-feedback">{{ error[0] }}</span>
</template>

<script>
export default {
    props: {
        label: String,
        modelValue: String,
        placeholder: String,
        rows: {
            type: Number,
            default: 3,
        },
        error: Array,
    },
};
</script>
