document.addEventListener("DOMContentLoaded", function () {
	const loader = document.getElementById('loader');
	loader.classList.add('show');

	window.onload = function () {
		setTimeout(() => {
			loader.classList.remove('show');
			loader.style.visibility = 'hidden';
			loader.remove();
		}, 100);
	};
});
