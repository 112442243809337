<template>
    <ckeditor
        v-model="editorData"
        :editor="editor"
        :config="editorConfig"
        @blur="emitContentChange"
    ></ckeditor>
</template>

<script>

import {ClassicEditor, Bold, Essentials, Italic, Mention, Paragraph, Undo, Heading, Link, List} from 'ckeditor5';

export default {
    props: {
        modelValue: String,
    },
    data() {
        return {
            editorData: this.modelValue || '',
            editor: ClassicEditor,
            editorConfig: {
                placeholder: 'Введите текст..',
                plugins: [Bold, Essentials, Italic, Paragraph, Undo, Mention, Heading, Link, List],
                toolbar: ['heading', '|', 'bold', 'italic', '|', 'link', 'bulletedList'],
            }
        };
    },
    emits: ['update:modelValue', 'content-changed'],
    watch: {
        modelValue(newValue) {
            if (newValue !== this.editorData) {
                this.editorData = newValue || '';
            }
        },
        editorData(newValue) {
            this.$emit('update:modelValue', newValue);
        }
    },
    methods: {
        emitContentChange() {
            this.$emit('content-changed');
        },
    },
};
</script>

