<template>
    <div>
        <div class="form-group">
            <FormSelect
                label="Язык"
                :options="locales"
                v-model="form.locale"
                :error="errors.locale"
                defaultOption="Язык"
            />
        </div>

        <div class="form-group">
            <FormInput
                label="Название"
                v-model="form.title"
                placeholder="Название"
                :error="errors.title"
            />
        </div>

        <div class="form-group">
            <FormInput
                label="ЧПУ для ссылки (задать или оставить пустым для автоматической генерации)"
                v-model="form.slug"
                placeholder="ЧПУ"
                :error="errors.slug"
            />
        </div>

        <div class="form-group">
            <FormSelect
                label="Категория"
                :options="categories"
                v-model="form.category_id"
                :error="errors.category_id"
                defaultOption="Категория"
            />
        </div>

        <div class="form-group">
            <FormTextarea
                label="Краткое описание"
                :rows="5"
                v-model="form.intro"
                placeholder="Описание"
                :error="errors.intro"
            />
        </div>

        <div class="form-group">
            <FormTextarea
                label="Description"
                v-model="form.description"
                placeholder="Description"
                :error="errors.description"
            />
        </div>

        <div class="form-group">
            <FormInput
                label="Ключевые слова"
                v-model="form.keywords"
                placeholder="Keywords"
                :error="errors.keywords"
            />
        </div>

        <div class="form-group">
            <label for="thumbnail">Превью</label>
            <input
                type="file"
                @change="onFileChange"
                class="form-control"
                id="thumbnail"
            />
            <span v-if="errors.thumbnail" class="invalid-feedback">{{ errors.thumbnail[0] }}</span>
        </div>

        <img v-if="typeof form.thumbnail === 'string'" :src="form.thumbnail" class="thumbnail-post" alt="thumbnail">

        <div class="form-group">
            <FormSelect
                label="Статус"
                :options="statuses"
                v-model="form.status"
                :error="errors.status"
                defaultOption="Статус"
            />
        </div>

        <div class="form-group">
            <button @click="submitForm" class="btn btn-success">
                {{ isEditMode ? 'Сохранить изменения' : 'Создать' }}
            </button>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import FormInput from "../../Admin/Form/FormInput.vue";
import FormTextarea from "../../Admin/Form/FormTextarea.vue";
import FormSelect from "../../Admin/Form/FormSelect.vue";

export default {
    components: {FormSelect, FormTextarea, FormInput},
    props: {
        translation: Object,
        locales: Object,
        categories: Array,
        statuses: Array,
    },
    data() {
        return {
            form: {
                locale: this.locales[0]?.id,
                title: '',
                slug: '',
                category_id: this.categories[0]?.id || '',
                intro: '',
                description: '',
                keywords: '',
                thumbnail: null,
                status: this.statuses[0]?.id,
            },
            errors: {},
        };
    },
    computed: {
        isEditMode() {
            return Boolean(this.translation);
        },
    },
    mounted() {
        if (this.isEditMode) {
            this.form = {
                locale: this.translation.locale,
                title: this.translation.title,
                category_id: this.translation.post.category_id,
                slug: this.translation.slug,
                intro: this.translation.intro,
                description: this.translation.description,
                keywords: this.translation.keywords,
                thumbnail: this.translation.post.thumbnail.original_url || null,
                status: this.translation.status,
            };
        }
    },
    methods: {
        onFileChange(event) {
            const file = event.target.files[0];
            if (file) {
                this.form.thumbnail = file;
            }
        },
        async submitForm() {
            try {
                const url = this.isEditMode
                    ? `/admin/posts/translation/${this.translation.id}/update`
                    : '/admin/posts/store';

                const formData = new FormData();
                formData.append('locale', this.form.locale);
                formData.append('title', this.form.title);
                formData.append('description', this.form.description);
                formData.append('keywords', this.form.keywords);
                formData.append('category_id', this.form.category_id);
                formData.append('intro', this.form.intro);
                formData.append('slug', this.form.slug);
                formData.append('status', this.form.status);

                // Добавляем файл только если он выбран
                if (this.form.thumbnail) {
                    formData.append('thumbnail', this.form.thumbnail);
                }

                const response = await axios({
                    method: 'post',
                    url,
                    data: formData,
                    headers: { 'Content-Type': 'multipart/form-data' }
                });

                if (response.data.success) {
                    alert(response.data.message);
                    window.location.href = response.data.url;
                }
            } catch (error) {
                if (error.response && error.response.status === 422) {
                    this.errors = error.response.data.errors;
                } else {
                    console.error('Произошла ошибка:', error);
                }
            }
        },
    },
};
</script>